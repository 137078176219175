import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

const navTrigger = document.querySelector('.js-nav-trigger');
const nav = document.querySelector('.nav');

function initNav() {
  document.addEventListener('click', (event) => {
    if (document.body.classList.contains('js-nav-active') && !event.target.closest('.nav')) {
      document.body.classList.remove('js-nav-active');
      enableBodyScroll(nav);
    }
    if (event.target == navTrigger) {
      document.body.classList.add('js-nav-active');
      disableBodyScroll(nav);
    }
  });
}

if (navTrigger) {
  initNav();
}
