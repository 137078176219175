import Headroom from 'headroom.js';

var header = document.querySelector('header');
var headroomOptions = {
  offset: window.innerHeight / 6,
  tolerance: 15,
};
var headroom = new Headroom(header, headroomOptions);

headroom.init();
