document.addEventListener("DOMContentLoaded", () => {
  const banner = document.querySelector(".banner--support");
  const supportSection = document.querySelector("#support");

  if (!banner) return;

  setTimeout(() => {
    banner.classList.add("visible");
  }, 500);

  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          banner.classList.remove("visible");
          banner.classList.add("hidden");
        } else {
          banner.classList.remove("hidden");
          banner.classList.add("visible");
        }
      });
    },
    { threshold: 0.1 }
  );

  const checkScrollPosition = () => {
    if (window.scrollY > 1200) {
      banner.classList.remove("visible");
      banner.classList.add("hidden");
    } else {
      banner.classList.remove("hidden");
      banner.classList.add("visible");
    }
  };

  if (supportSection) {
    // Smooth scroll to anchor when banner is clicked
    if (window.location.hash) {
      const target = document.querySelector(window.location.hash);
      if (target) {
        target.scrollIntoView({ block: "start" });
      }
    }
    banner.addEventListener("click", (event) => {
      const targetId = banner.getAttribute("href");
      const targetElement = document.querySelector(targetId);
      if (targetElement) {
        event.preventDefault();
        targetElement.scrollIntoView({ behavior: "smooth" });
      }
    });
    observer.observe(supportSection);
  } else {
    window.addEventListener("scroll", checkScrollPosition);
  }
});
