document.querySelector('.form--newsletter').addEventListener('submit', function (e) {
  e.preventDefault();
  document.querySelector('.form__feedback--success').textContent = '';
  document.querySelector('.form__feedback--error').textContent = '';

  const url = new URL('https://api.sender.net/v2/subscribers');
  const apiKey =
    'eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiIxIiwianRpIjoiMWRiNThiYmM4YmQ5MjdjZjU2NDc1ZWJmNGI0OTkxYmJmMGE1NWIyOTZjZGZiNDJhMDFmYzJlNGQ5NWU3NmJiMWQ4NGU5N2I1YzQ4Yjg4ZTkiLCJpYXQiOiIxNjg1NzExMTU3Ljk3NDcwMSIsIm5iZiI6IjE2ODU3MTExNTcuOTc0NzA0IiwiZXhwIjoiNDgzOTMxMTE1Ny45NzEzNjAiLCJzdWIiOiI3NzgzNzIiLCJzY29wZXMiOltdfQ.wVieQCzefR0it0TTAC_8zcgwCVgq8gNFnlzTSjDqrDm-gUBET2cyNfn9fErTzyFElQgxCYAqAeuP8pUtfB-JUA';
  const language = document.documentElement.lang;
  const errorMessages = {
    en: 'An error occurred. Please try again later.',
    de: 'Es ist ein Fehler aufgetreten. Bitte versuche es später erneut.',
  };
  const successMessages = {
    en: 'Great, we have received your data! Check your email inbox.',
    de: 'Super, wir haben deine E-Mail erhalten! Prüfe deinen E-Mail Posteingang.',
  };
  const newsletterGroups = {
    de: 'eZ0gOJ',
    en: 'eX59JV',
  };

  let data = {
    email: document.querySelector('#emailInput').value,
    groups: [newsletterGroups[language]],
  };

  let headers = {
    Authorization: `Bearer ${apiKey}`,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  fetch(url, {
    method: 'POST',
    headers,
    body: JSON.stringify(data),
  })
    .then((response) => {
      if (response.ok) {
        document.querySelector('#emailInput').value = '';
        document.querySelector('.form__feedback--success').textContent = successMessages[language];
      } else {
        document.querySelector('.form__feedback--error').textContent = errorMessages[language];
      }
    })
    .catch((error) => {
      document.querySelector('.form__feedback--error').textContent = errorMessages[language];
      console.log(error);
    });
});
